<template>
  <main-template>
    <v-row v-if="etapas">
      <v-col cols="12">
        <v-card v-if="turma && disciplina && professor && matriculas">
          <v-card-title>Lançando notas</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <!-- <h4>Disciplina: {{ disciplina.descricao }}</h4>
            <h4>Professor: {{ professor.nome }}</h4> -->
            <h4>Qtd. alunos: {{ matriculas.length }}</h4>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-tabs background-color="primary" dark v-model="tab_atual" icons-and-text>
            <v-tab
              @click="loadSubetapas(tab.id, etapas.indexOf(tab))"
              v-for="tab of etapas"
              :key="tab.id"
            >
              {{ tab.descricao }}
            </v-tab>
          </v-tabs>
          <v-card-text>
            <div v-text="'Ciclo dessa Etapa'" class="text-overline font-weight-bold"></div>
            <v-alert
              text
              border="left"
              color="deep-green"
              icon="calendar_month"
              class="text-caption"
            >
              <span class="font-weight-bold"
                >De {{ validarData(currentEtapa.periodo_inicial) }}</span
              >
              <span class="font-weight-bold"> à {{ validarData(currentEtapa.periodo_final) }}</span>
            </v-alert>
            <v-simple-table v-if="matriculas" class="elevation-1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">N º</th>
                    <th
                      v-if="user.roleName == 'super-admin' || user.roleName == 'admin'"
                      class="text-left"
                    >
                      Identificador
                    </th>
                    <th class="text-left" width="300px">Aluno</th>
                    <th
                      v-for="sub of subetapas"
                      :key="subetapas.indexOf(sub)"
                      class="text-left"
                      v-show="tab_atual === etapaAtual"
                    >
                      {{ sub.descricao }}
                      {{ sub.valor_maximo != 10 ? `(Nota Máxima: ${sub.valor_maximo})` : "" }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="matricula of matriculas"
                    :key="matricula.id"
                    :class="
                      $constants.situacoesMatriculaCondition[
                        removerEspaco(matricula.situacao ? matricula.situacao : '')
                      ]
                        ? 'red lighten-4'
                        : null
                    "
                  >
                    <td>{{ matriculas.indexOf(matricula) + 1 }}</td>
                    <td v-if="user.roleName == 'super-admin' || user.roleName == 'admin'">
                      {{ matricula.id }}
                    </td>
                    <td width="300px">
                      {{ matricula.aluno.nomecompleto }}

                      <v-chip
                        label
                        color="error"
                        small
                        v-if="
                          $constants.situacoesMatriculaCondition[
                            removerEspaco(matricula.situacao ? matricula.situacao : '')
                          ]
                        "
                        >{{ matricula.situacao }}</v-chip
                      >
                    </td>
                    <!-- notas nao  salvas -->
                    <template v-if="notas[matricula.id].valores">
                      <td
                        v-show="tab_atual == etapaAtual"
                        v-for="sub of subetapas"
                        :key="subetapas.indexOf(sub)"
                      >
                        <e-nota-input-resultado
                          :matricula="matricula"
                          v-if="
                            notas[matricula.id].valores[sub.id] &&
                            sub.tipo_campo === 'resultado_em_lote'
                          "
                          v-model="notas[matricula.id].valores[sub.id]"
                          :value="notas[matricula.id].valores[sub.id]"
                        />

                        <e-nota-input-resultado-texto
                          :matricula="matricula"
                          v-else-if="sub.tipo_campo === 'resultado_em_texto'"
                          v-model="notas[matricula.id].valores_texto[sub.id]"
                          :value="notas[matricula.id].valores_texto[sub.id]"
                        />
                        <e-nota-input
                          :tipoValor="sub.tipo_campo"
                          :etapaAtual="sub.etapa_id"
                          :circuitoId="circuitoId"
                          :max="sub.valor_maximo"
                          :matricula="matricula"
                          :notaTextoLetra="notas[matricula.id].valores_texto[sub.id]"
                          v-else
                          v-model="notas[matricula.id].valores[sub.id]"
                          @change="verificarRegra(sub.etapa_id, matricula.id, 'naoSalva')"
                          @add="
                            adicionarValorTextoEmNota($event, {
                              matriculaId: matricula.id,
                              subetapaId: sub.id,
                            })
                          "
                        />
                      </td>
                    </template>
                    <!-- notas ja salvas -->
                    <template v-else>
                      <td
                        v-show="tab_atual == etapaAtual"
                        v-for="sub of subetapas"
                        :key="subetapas.indexOf(sub)"
                      >
                        <e-nota-input
                          :tipoValor="sub.tipo_campo"
                          :circuitoId="circuitoId"
                          :max="sub.valor_maximo"
                          :matricula="matricula"
                          :notaTexto="notas[matricula.id][sub.id]"
                          v-if="
                            (notas[matricula.id][sub.id] && sub.tipo_campo === 'valor') ||
                            sub.tipo_campo === 'valor_texto'
                          "
                          v-model="notas[matricula.id][sub.id].valor"
                          @change="verificarRegra(sub.etapa_id, matricula.id, 'jaSalva')"
                          @add="
                            adicionarValorTextoEmNotaJaSalva($event, {
                              matriculaId: matricula.id,
                              subetapaId: sub.id,
                            })
                          "
                        />
                        <!-- <e-nota-input
                          :tipoValor="sub.tipo_campo"
                          :circuitoId="circuitoId"
                          :max="sub.valor_maximo"
                          :matricula="matricula"
                          :notaTexto = "notas[matricula.id][sub.id]"
                          v-if="notas[matricula.id][sub.id] && sub.tipo_campo === 'valor' || sub.tipo_campo === 'valor_texto'"
                          v-model="notas[matricula.id][sub.id].valor"
                          @change="verificarRegra(sub.etapa_id, matricula.id, 'jaSalva')"
                          @add="adicionarValorTextoEmNotaJaSalva($event, {matriculaId: matricula.id ,subetapaId:sub.id})"
                        /> -->

                        <e-nota-input-resultado
                          :matricula="matricula"
                          v-else-if="
                            notas[matricula.id][sub.id] && sub.tipo_campo === 'resultado_em_lote'
                          "
                          v-model="notas[matricula.id][sub.id].valor"
                          :value="notas[matricula.id][sub.id].valor"
                        />

                        <e-nota-input-resultado-texto
                          :matricula="matricula"
                          v-else-if="
                            notas[matricula.id][sub.id] && sub.tipo_campo === 'resultado_em_texto'
                          "
                          v-model="notas[matricula.id][sub.id].valor_texto"
                          :value="notas[matricula.id][sub.id].valor_texto"
                        />
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-expand-transition>
              <div class="d-flex justify-end mt-2" v-if="temAlteracoesNaoSalvas">
                <span class="font-weight-bold warning--text"
                  >Você tem algumas notas não salvas...</span
                >
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn color="success" :loading="promiseCount > 0" block @click="salvarNotas"
          >Enviar Notas</v-btn
        >
      </v-col>
    </v-row>
    <v-alert v-else text-border="left" color="orange" icon="warning" class="text-caption"
      >Lançamento de Notas Indisponível, não existe nenhum circuito de notas para essa
      turma.</v-alert
    >
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dateFormatParam } from "../../plugins/filters";

const TIMEOUT = 30000;
const NOTA_MINIMA = 6;

export default {
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      circuitoTipo: null,
      etapaAtual: 0,
      circuitoName: null,
      etapas: null,
      regras: null,
      NOTA_MINIMA,
      notas: null,
      disciplina: null,
      turma: null,
      professor: null,
      matriculas: null,
      tab_atual: 0,
      teste: null,
      tabs: [],
      promiseCount: 0,
      temAlteracoesNaoSalvas: true,
      subetapas: null,
      idEtapaAtual: null,
      currentEtapa: null,
      circuitoId: null,
      addNulo: false,
    };
  },
  methods: {
    adicionarValorTextoEmNota($event, payload) {
      this.notas[payload.matriculaId].valores_texto[payload.subetapaId] = $event;
    },
    adicionarValorTextoEmNotaJaSalva($event, payload) {
      this.notas[payload.matriculaId][payload.subetapaId].valor_texto = $event;
    },
    loadSubetapas(etapa_id, indexEtapa) {
      this.etapaAtual = indexEtapa;
      this.idEtapaAtual = etapa_id;

      const etapas = Object.values(this.etapas);

      let etapa = etapas.filter((d) => d.id === etapa_id);
      etapa = etapa.shift();
      this.currentEtapa = etapa;

      this.subetapas = etapa.subetapas;
      const firstItemNota = this.notas[Object.keys(this.notas).shift()];

      if (firstItemNota.valores) {
        this.verificarRegraEmLote(etapa_id, "naoSalva");
      } else {
        this.verificarRegraEmLote(etapa_id, "jaSalva");

        // limpar inputs das outras etapas
        etapa.subetapas.forEach((subetapa) => {
          this.matriculas.forEach((matricula) => {
            if (this.notas[matricula.id][subetapa.id].valor === null) {
              this.notas[matricula.id][subetapa.id].valor = "0.00";
            }
            if (this.notas[matricula.id][subetapa.id].valor === "0.00") {
              this.notas[matricula.id][subetapa.id].valor = "0.00";
            }
          });
        });
      }
    },

    async loadData() {
      this.$loaderService.open("Buscando notas");
      try {
        // dados circuito e etapas
        const data = await this.$services.notasService.getCircuitoEtapas(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.etapas = data.etapas;
        this.regras = data.regras;
        this.circuitoId = data.id;
        this.circuitoName = data.descricao;
        this.circuitoTipo = data.tipo;
        if (this.etapas) {
          const firstEtapa = Object.values(this.etapas)[0];
          this.subetapas = firstEtapa.subetapas;
          this.currentEtapa = firstEtapa;
          // dados notas
          const payload = await this.$services.notasService.getNotasCircuito(
            parseInt(this.$route.params.gestaoDeAulasId, 10)
          );
          this.notas = payload.notasCircuito;
          this.disciplina = payload.disciplina;
          this.turma = payload.turma;
          this.professor = payload.professor;
          this.matriculas = payload.matriculas;

          const firstItemNota = this.notas[Object.keys(this.notas).shift()];

          if (!firstItemNota.valores) {
            this.verificarRegraEmLote(null, "jaSalva");

            // limpar inputs das outras etapas
            firstEtapa.subetapas.forEach((subetapa) => {
              this.matriculas.forEach((matricula) => {
                if (this.notas[matricula.id][subetapa.id].valor === null) {
                  this.notas[matricula.id][subetapa.id].valor = "";
                }
                if (this.notas[matricula.id][subetapa.id].valor === "0.00") {
                  this.notas[matricula.id][subetapa.id].valor = "";
                }
              });
            });
          } else {
            this.verificarRegraEmLote(null, "naoSalva");
          }

          this.$loaderService.close();
          this.$forceUpdate();
        }
        this.$loaderService.close();
      } catch (error) {
        this.$loaderService.close();
        // this.$handleError(error);
      }
    },

    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    verificarSituacao() {
      return true;
    },
    // calcularMedia(nota1, nota2) {
    //   const media =
    //     ((nota1 ? parseFloat(nota1, 10) : 0) + (nota2 ? parseFloat(nota2, 10) : 0)) / 2.0;
    //   return Math.trunc(media * 10) / 10.0;
    // },

    async salvarNotas() {
      if (!this.temAlteracoesNaoSalvas) {
        this.$toast.info("Todas as notas estão salvas no sistema 😁");
        return null;
      }
      this.$toast.info("Salvando notas...");
      this.promiseCount += 1;
      let response;
      try {
        response = await this.$services.notasService.salvarNotasCircuito(
          parseInt(this.$route.params.gestaoDeAulasId, 10),
          this.notas
        );

        const firstItemNota = this.notas[Object.keys(this.notas).shift()];

        if (firstItemNota.valores && this.circuitoTipo === "letras") {
          window.location.reload();
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.promiseCount -= 1;
      if (this.promiseCount === 0) {
        this.temAlteracoesNaoSalvas = true;
      }
      return response;
    },
    async handleChanges() {
      if (this.$route.name !== "notas.circuitoNotas") return;
      setTimeout(() => this.handleChanges(), TIMEOUT);
    },

    verificarRegraEmLote(etapaId, situacao) {
      if (etapaId) {
        let etapa = this.etapas.filter((d) => d.id === etapaId);
        etapa = etapa.shift();
        etapa.subetapas.forEach((subetapa) => {
          if (
            subetapa.tipo_campo === "resultado_em_lote" ||
            subetapa.tipo_campo === "resultado_em_texto"
          ) {
            this.matriculas.forEach((matricula) => {
              const payload = { etapaId: etapa.id, matriculaId: matricula.id, situacao };
              if (this.circuitoTipo === "letras") {
                this.calcularRegraTipoTexto(payload);
              } else {
                this.verificarRegra(etapa.id, matricula.id, situacao);
              }
            });
          }
        });
      } else {
        const etapa = this.etapas[0];

        etapa.subetapas.forEach((subetapa) => {
          if (
            subetapa.tipo_campo === "resultado_em_lote" ||
            subetapa.tipo_campo === "resultado_em_texto"
          ) {
            this.matriculas.forEach((matricula) => {
              const payload = { etapaId: etapa.id, matriculaId: matricula.id, situacao };
              if (this.circuitoTipo === "letras") {
                this.calcularRegraTipoTexto(payload);
              } else {
                this.verificarRegra(etapa.id, matricula.id, situacao);
              }
            });
          }
        });
      }
    },
    verificarRegra(etapa_id, matricula_id, type) {
      let etapaCurrent = this.etapas.filter((d) => d.id === etapa_id);
      etapaCurrent = etapaCurrent.shift();

      etapaCurrent.regras.forEach((regra) => {
        if (regra) {
          let expressaoCod = regra.expressao_codigo;
          const variaveis = regra.subetapas.split(",");
          const campo_resultado = regra.id_subetapa_resultado;
          let resultadoCondicao = null;
          resultadoCondicao = "";
          let cor = null;
          cor = "";

          if (type !== "naoSalva") {
            const promisse = new Promise(() => {
              const av = [];
              variaveis.forEach((element) => {
                av[element] = parseFloat(this.notas[matricula_id][element].valor);
              });
              // eslint-disable-next-line no-eval
              return (expressaoCod = eval(expressaoCod));
            });

            this.teste = promisse;
            this.mostrarResultado(matricula_id, campo_resultado, expressaoCod, "salva");
          } else {
            const promisse = new Promise(() => {
              const av = [];
              variaveis.forEach((element) => {
                av[element] = parseFloat(this.notas[matricula_id].valores[element]);
              });

              // eslint-disable-next-line no-eval
              return (expressaoCod = eval(expressaoCod));
            });
            this.teste = promisse;
            this.mostrarResultado(matricula_id, campo_resultado, expressaoCod, "naoSalva");
          }
        }
      });
    },
    mostrarResultado(matricula_id, campo_resultado, expressaoCod, situacao) {
      const subetapaDados = this.subetapas.filter((sub) => sub.id === campo_resultado)[0];
      const isText = subetapaDados.tipo_campo === "resultado_em_texto";
      switch (situacao) {
        case "salva":
          if (isText) {
            // regra de forma statica:
            // this.notas[matricula_id][campo_resultado].valor_texto_cor = 'blue' || null;
            this.notas[matricula_id][campo_resultado].valor_texto = expressaoCod || null;
          } else {
            this.notas[matricula_id][campo_resultado].valor = expressaoCod || null;
          }

          break;
        case "naoSalva":
          if (isText) {
            // regra de forma statica:
            // this.notas[matricula_id].valores_texto_cor[campo_resultado] = 'blue';
            this.notas[matricula_id].valores_texto[campo_resultado] = expressaoCod;
          } else {
            this.notas[matricula_id].valores[campo_resultado] = expressaoCod;
          }

          break;

        default:
          break;
      }
    },
    calcularRegraTipoTexto(payload) {
      // verificar regra
      let etapaCurrent = this.etapas.filter((d) => d.id === payload.etapaId);
      etapaCurrent = etapaCurrent.shift();

      etapaCurrent.regras.forEach((regra) => {
        if (regra) {
          const campo_resultado = regra.id_subetapa_resultado;
          let resultadoCondicao = null;
          resultadoCondicao = "";
          let cor = null;
          cor = "";

          // pegar notas das tabs

          let notas = Object.values(this.notas[payload.matriculaId]);
          if (payload.situacao === "naoSalva") {
            notas = Object.values(this.notas[payload.matriculaId].valores_texto);
          } else {
            notas = notas.map((item) => item.valor_texto);
          }

          // executar eval
          // eslint-disable-next-line no-eval
          resultadoCondicao = eval(regra.expressao_codigo);

          if (resultadoCondicao) {
            if (payload.situacao === "naoSalva") {
              this.mostrarResultado(
                payload.matriculaId,
                campo_resultado,
                resultadoCondicao,
                "naoSalva"
              );
            } else {
              this.mostrarResultado(
                payload.matriculaId,
                campo_resultado,
                resultadoCondicao,
                "salva"
              );
            }
          }
        }
      });
    },
    validarData(data) {
      return dateFormatParam(data, "d/m/y");
    },
  },
};
</script>
