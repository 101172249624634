<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Métricas da turma
        </h1>
      </v-col>
    </v-row>
    <v-card class="mx-auto my-3">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>

      <v-card-title>Turma</v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0">
          <div class="grey--text">
            {{ turma ? turma.descricao : "- - -" }}
          </div>
        </v-row>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-row>
        <e-zona-card :turmaId="turma?.id" />
        <e-zona-card-nota :turmaId="turma?.id" />
      </v-row>

      <v-row class="mt-3 mb-1">
        <v-col cols="12">
          <v-chip color="indigo" label text-color="white"> Melhores alunos</v-chip>
          <v-card v-for="(item, i) in melhoresAlunos" :key="i" light class="mb-3 mt-1">
            <div class="d-flex flex-no-wrap justify-content">
              <div style="border-right: 1px solid #ccc" class="py-5">
                <div class="d-flex flex-no-wrap justify-content">
                  <v-img width="100" :src="coroas[i]"></v-img>
                </div>
                <div style="text-align: center" class="grey--text">
                  <h3>{{ ++i }}</h3>
                </div>
              </div>

              <div class="py-5 ml-2">
                <div>
                  <h3 class="indigo--text mb-3">
                    {{ item.matricula.aluno.nomecompleto }}
                  </h3>
                </div>
                <v-divider></v-divider>
                <div class="mt-3">
                  <span class="">Média: {{ item.valor }}</span> <br />
                  <span class="">Faltas: 0</span>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      turma: null,
      melhoresAlunos: [],
      alunos: [],
      coroas: [
        "https://img.freepik.com/vetores-gratis/composicao-da-coroa-real-com-imagem-plana-da-coroa-cercada-por-coroas-de-louro-douradas_1284-64252.jpg?w=740&t=st=1661362754~exp=1661363354~hmac=29523ea348e1807edaa9ca4d5abf172c4753f0e7d8a6a968a3c2ff064eaa087a",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
        "https://static.vecteezy.com/system/resources/previews/000/554/920/large_2x/royal-crown-vector-icon.jpg",
      ],
    };
  },
  mounted() {
    this.getTurmaId();
    this.loadData();
    this.getAlunos();
  },
  methods: {
    getTurmaId() {
      const { id } = this.$route.params;
      this.turmaId = id;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        const data = await this.$services.turmasService.mostrarTurma(this.turmaId);
        if (data.data.turma) this.turma = { ...data.data.turma };
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async getAlunos() {
      try {
        this.$loaderService.open("Carregando alunos");
        const data = await this.$services.turmasService.getMelhoresTurma(this.turmaId);
        console.log("console:", data.data);
        this.melhoresAlunos = data.data;

        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
