<template>
  <v-col sm="12" xs="12" cols="12" md="6">
    <v-card :loading="loading" class="pt-2" height="400">
      <div class="ma-5">
        <h5>Desempenho dos alunos por ano escolar</h5>
      </div>
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>
      <apexchart width="500" type="line" :options="options" :series="series"></apexchart>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "EvolucaoAlunoNotas",
  mounted() {
    this.getZona();
  },
  props: {
    turmaId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    turmaId() {
      console.log("opa mudou turma");
      this.getZonaByTurma(this.turmaId);
    },
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [
            "1º Ano",
            "2º Ano",
            "3º Ano",
            "4º Ano",
            "5º Ano",
            "6º Ano",
            "7º Ano",
            "8º Ano",
            "9º Ano",
          ],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 32, 40, 42, 39, 35, 42, 51, 50],
        },
      ],
    };
  },
  methods: {
    async getZona() {
      const response = await this.$services.alunosService.zona();
      const payload = Object.values(response.data);
      console.log("payload: ", payload);
      this.loading = false;
      this.series = [];
      this.series.push(parseInt(payload[0], 10));
      this.series.push(parseInt(payload[1], 10));
    },
    async getZonaByTurma(id) {
      const response = await this.$services.alunosService.zonaByTurma(id);
      const payload = Object.values(response.data);
      console.log("payload by turma: ", payload);
      this.loading = false;
      this.series = [];
      this.series.push(parseInt(payload[0], 10));
      this.series.push(parseInt(payload[1], 10));
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
