import Etapa from "@/Models/Etapa";
import Regra from "@/Models/Regra";
import Axios from "@/plugins/Axios";
import store from "@/store";

export class RegrasService {
  async syncAll() {
    const response = await Axios().get("regras");
    const regras = response.data.map((regra) => new Regra(regra));
    return regras;
  }

  async syncAllRegrasDisponiveis(circuitoId, gestaoDeAulaId) {
    const response = await Axios().get(
      `regras?circuito_nota_id=${circuitoId}&type=dataAtual&gestaoDeAulaId=${gestaoDeAulaId}`
    );
    const regras = response.data.todas.map((regra) => new Regra(regra));
    return { regras, disponiveis: response.data.disponiveis };
  }

  async getRegrasByCircuitoNotaId(circuitoId) {
    const response = await Axios().get(`regras?circuito_nota_id=${circuitoId}`);
    const regras = response.data.map((regra) => new Regra(regra));
    return regras;
  }

  async criarRegra(regra) {
    const response = await Axios().post("regras", regra);
    const novoRegra = response.data;
    return novoRegra;
  }

  async atualizarRegra(circuito) {
    const response = await Axios().put(`regras/${circuito.id}`, circuito);
    const regra = response.data;
    return regra;
  }

  async deletarRegra(circuito) {
    const response = await Axios().delete(`regras/${circuito.id}`);
    const regraDeletada = response.data;
    return regraDeletada;
  }
}
export default new RegrasService();
