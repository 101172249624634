<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Mensal
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <!-- // Inicio cabeçalho -->
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="primary">
          <v-col cols="6">
            <v-chip class="white--text mb-3" color="blue" style="margin: 3px">
              <v-icon left> mdi-account-circle </v-icon>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
            </v-chip>
            <br />
            <v-chip color="white" class="blue--text" border style="margin: 3px">
              Serie: {{ gestaoDeAula.turma?.series?.descricao }}
            </v-chip>
            <v-chip color="white" class="blue--text" style="margin: 3px">
              Turma: {{ gestaoDeAula.turma?.descricao }}
            </v-chip>
            <v-chip color="white" class="blue--text" style="margin: 3px">
              Disciplina: {{ gestaoDeAula.disciplina?.descricao }}
            </v-chip>
            <v-chip color="white" class="blue--text" style="margin: 3px">
              Turno: {{ gestaoDeAula.turma?.turno?.descricao }}
            </v-chip>
            <br />
            <br />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  class="ml-1 white--text"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => verHistoricoPlanos()"
                >
                  <span>Histórico do lancamento de planos</span>
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-card>
      </v-col>
      <!-- // Fim cabeçalho -->
    </v-row>

    <e-overlay v-if="overlayOpen" :overlay="overlay" />

    <v-alert
      v-if="overlayOpen"
      :color="overlay.descricao?.toUpperCase() == 'PLANO INVÁLIDO' ? 'red' : 'green'"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-alert"
      class="mt-10"
      prominent
    >
      <v-col>
        Esse plano está com status de <strong>{{ overlay.descricao?.toUpperCase() }}</strong
        >, por isso não pode mais ser alterado! <br />
        Para mais informações, entre em contato com o coordenador.
      </v-col>
    </v-alert>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row class="pt-5">
          <v-col cols="12">
            <a
              href="https://drive.google.com/drive/folders/0B9sasL8AsTgjfjBIQ2h4MVdic0doS0RGdTZqTGhnQWxnSzd1RXhYM2hURzE5dEpTd2ZpNVE?resourcekey=0-iPEaI0tdqnnh_laG2DNOyA"
              target="_blank"
            >
              <v-btn color="blue" class="white--text">
                <v-icon class="mr-2">folder</v-icon>
                Documentos norteadores
              </v-btn>
            </a>

            <v-card class="pa-md-4 mt-5">
              <v-select
                :items="meses"
                item-text="descricao"
                item-value="id"
                v-model="selectMonth"
                return-object
                label="Selecione um mês"
                outlined
                dense
              >
              </v-select>
              <v-select
                :items="periodos"
                item-text="descricao"
                item-value="id"
                v-model="periodoSelecionado"
                return-object
                label="Selecione um periodo"
                outlined
                dense
              >
              </v-select>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <!-- UNIDADES TÉMATICAS / PRÁTICAS DE LINGUAGEM -->
          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM </label>
                <ValidationProvider name="unidades temáticas" rules="required" v-slot="{ errors }">
                  <v-textarea
                    rows="3"
                    v-model="tematica"
                    placeholder="Digite aqui"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </div>
            </v-card>
          </v-col>
          <!-- UNIDADES TÉMATICAS / PRÁTICAS DE LINGUAGEM -->

          <!-- CONTEÚDO | ASSUNTO | OBJETOS DO CONHECIMENTO -->
          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> CONTEÚDO | ASSUNTO | OBJETOS DO CONHECIMENTO </label>
                <ValidationProvider name="objeto conhecimento" rules="required" v-slot="{ errors }">
                  <v-textarea
                    rows="3"
                    :error-messages="errors"
                    v-model="objeto_conhecimento"
                    placeholder="Digite aqui"
                  ></v-textarea>
                </ValidationProvider>
              </div>
            </v-card>
          </v-col>
          <!-- CONTEÚDO | ASSUNTO | OBJETOS DO CONHECIMENTO -->

          <!-- INICIO HABILIDADES -->
          <v-col cols="12">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-3 mt-5" color="blue" dark v-bind="attrs" v-on="on">
                  INSIRA O CÓDIGO DCTMA
                </v-btn>
              </template>
              <v-card>
                <v-toolbar dark color="blue">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>PESQUISA</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-col>
                  <v-row>
                    <v-col cols="12">
                      <v-sheet color="white" elevation="1">
                        <div class="py-2 px-4" color="white">
                          <v-text-field
                            v-model="search"
                            autofocus
                            v-on:keyup="() => procurarHabilidade()"
                            label="DIGITE O CODIGO DCTMA"
                          ></v-text-field>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-sheet color="white" elevation="1" rounded>
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            HABILIDADES ENCONTRADAS
                            <v-icon dark right> mdi-magnify </v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>
                        <div class="py-2 px-4">
                          <v-list rounded>
                            <v-list-item-group>
                              <v-list-item
                                class="grey lighten-3 mb-3 pa-md-4"
                                v-for="m in habilidadesPesquisa"
                                :key="m.id"
                              >
                                [ {{ m.codigo }} ] {{ m.descricao }}
                                <v-btn
                                  @click="() => selecionarHabilidade(m)"
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="blue"
                                >
                                  <v-icon dark> mdi-plus </v-icon>
                                </v-btn>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-sheet>
                    </v-col>

                    <v-col cols="6">
                      <v-sheet color="white" elevation="1">
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            Habilidades selecionados
                            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>
                        <div class="py-2 px-4">
                          <v-list rounded>
                            <v-list-item-group>
                              <v-list-item
                                class="grey lighten-3 mb-3 pa-md-4"
                                v-for="(m, index) in habilidadesSelecionadas"
                                :key="m.id"
                              >
                                <v-btn
                                  @click="() => deletarHabilidade(index)"
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="red"
                                >
                                  <v-icon dark> mdi-delete </v-icon>
                                </v-btn>
                                {{ m.codigo }} - {{ m.descricao }}
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-sheet>
                    </v-col>

                    <v-col rols="12">
                      <v-expansion-panels :value="0">
                        <v-expansion-panel>
                          <v-expansion-panel-header class="white--text" color="error">
                            A DISCIPLINA DE FILOSOFIA ESTÁ ISENTA DE CÓDIGO (DIGITE AS HABILIDADES
                            ABAIXO)
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-textarea
                              v-model="habilidadesObjetivo"
                              placeholder="Somente para a disciplina de filosofia"
                              rows="3"
                              no-resize
                              auto-grow
                            ></v-textarea>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-dialog>

            <v-card class="pa-md-4">
              <div>
                <label for=""> HABILIDADES EXPLORADAS </label>
                <v-list rounded>
                  <v-list-item-group color="primary">
                    <v-list-item v-if="habilidadesSelecionadas?.length === 0">
                      Nenhuma habilidade selecionada
                    </v-list-item>

                    <v-list-item
                      class="grey lighten-3 mb-3 pa-md-4"
                      v-for="(m, index) in habilidadesSelecionadas"
                      :key="m.id"
                    >
                      <v-btn
                        @click="() => deletarHabilidade(index)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                      {{ m.codigo }} - {{ m.descricao }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card>
          </v-col>
          <!-- FIM HABILIDADES -->
        </v-row>

        <!-- METODOLOGIAS -->
        <v-row>
          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">METODOLOGIAS</label>
                <ValidationProvider name="metodologias" rules="required" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="metodologiasSelecionadas"
                    :items="metodologias"
                    attach
                    chips
                    label="Marque uma ou mais opções"
                    multiple
                    :error-messages="errors"
                    item-text="descricao"
                    item-value="id"
                  ></v-autocomplete>
                </ValidationProvider>
                <v-textarea
                  v-model="metodologiaOutros"
                  placeholder="Digite aqui"
                  rows="3"
                  label="Se precisar dê mais detalhes de sua metodologia (NÃO OBRIGATÓRIO)"
                  no-resize
                  auto-grow
                ></v-textarea>
              </div>
            </v-card>
          </v-col>
          <!-- METODOLOGIAS -->

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">RECURSOS DE ENSINO</label>
                <ValidationProvider name="recursos de ensino" rules="required" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="recursosDeEnsinoSelecionados"
                    :items="recursosDeEnsino"
                    attach
                    chips
                    label="Marque uma ou mais opções"
                    multiple
                    :error-messages="errors"
                    item-text="descricao"
                    item-value="id"
                  ></v-autocomplete>
                </ValidationProvider>
                <v-textarea
                  v-model="recursosDeEnsinoSelecionadosOutros"
                  placeholder="Digite aqui"
                  rows="3"
                  label="Se precisar digite outras opções"
                  no-resize
                  auto-grow
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">AVALIAÇÃO</label>
                <ValidationProvider name="recursos de ensino" rules="required" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="avaliacoesSelecionadas"
                    :items="avaliacoes"
                    attach
                    chips
                    label="Marque uma ou mais opções"
                    multiple
                    :error-messages="errors"
                    item-text="descricao"
                    item-value="id"
                  ></v-autocomplete>
                </ValidationProvider>

                <v-textarea
                  v-model="avaliacoesSelecionadasOutros"
                  placeholder="Digite aqui"
                  rows="3"
                  label="Se precisar digite outras opções"
                  no-resize
                  auto-grow
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> BIBLIOGRAFIA </label>
                <v-textarea rows="3" v-model="bibliografia" placeholder="Digite aqui"></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-dialog v-model="dialogSerieVazia" persistent max-width="490">
            <v-card>
              <v-card-title class="text-h5"> Ops.. </v-card-title>
              <v-card-text
                >A Turma {{ gestaoDeAula.turma.descricao }} está sem serie.
                <p>Por favor adicione uma serie antes de continuar!</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="() => editarTurma(gestaoDeAula.turma)">
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">TIRA-DÚVIDAS ONLINE</label>
                <v-autocomplete
                  v-model="duvidasSelecionadas"
                  :items="duvidas"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
                <v-textarea
                  v-model="duvidasSelecionadasOutros"
                  placeholder="Digite aqui"
                  rows="3"
                  label="Se precisar digite outras opções"
                  no-resize
                  auto-grow
                ></v-textarea>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm || overlayOpen"
              :loading="submittingForm"
              type="submit"
            >
              Enviar Dados
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
    <e-drawer-planos-lancados
      v-model="openDrawer"
      :gestao_id="parseInt($route.params.gestaoDeAulaId, 10)"
      :bimestre="parseInt(form.bimestre, 10)"
    />
    <v-alert
      v-show="formRequest"
      class="my-2 animate__animated animate__headShake"
      dense
      border="left"
      type="warning"
    >
      Preencha todo o questionário
    </v-alert>
  </main-template>
</template>

<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  watch: {
    form() {
      this.formRequest = false;
    },
    // eslint-disable-next-line
    "form.status": function () {
      this.overlayOpen = false;
    },
    selectMonth() {
      this.selectPlano();
    },
    periodoSelecionado() {
      this.selectPlano();
    },
    overlay(val) {
      if (val?.descricao === "Aguardando confirmação" || val?.descricao === "") {
        this.overlayOpen = false;
      } else {
        this.overlayOpen = true;
      }
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      form: {
        mes_id: false,
        periodo_id: false,
        instrutorDisciplinaTurma_id: 0,
      },
      duvidas: [],
      duvidasSelecionadas: [],
      avaliacoes: [],
      avaliacoesSelecionadas: [],
      meses: [],
      periodos: [],
      habilidadesPesquisa: [],
      habilidadesSelecionadas: [],
      habilidades: [],
      metodologias: [],
      metodologiasSelecionadas: [],
      recursosDeEnsino: [],
      recursosDeEnsinoSelecionados: [],
      recursosDeEnsinoSelecionadosOutros: "",
      avaliacoesSelecionadasOutros: "",
      duvidasSelecionadasOutros: "",
      metodologiaOutros: "",
      submittingForm: false,
      formRequest: false,
      loadForm: false,
      openDrawer: false,
      dialogSerieVazia: false,
      search: "",
      dialog: false,
      habilidadesObjetivo: "",
      tematica: "",
      bibliografia: "",
      objeto_conhecimento: "",
      progress: 90,
      tab_atual: 0,
      overlay: {},
      overlayOpen: false,
      periodoSelecionado: {
        id: false,
        descricao: false,
      },
      selectMonth: {
        id: false,
        descricao: false,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
    };
  },
  methods: {
    selecionarHabilidade(m) {
      const habilidade = this.habilidadesSelecionadas?.find((h) => h.id === m.id);
      if (habilidade) return;
      this.habilidadesSelecionadas.push(m);
    },
    deletarHabilidade(index) {
      this.habilidadesSelecionadas.splice(index, 1);
    },
    editarTurma(turma) {
      this.$router.push({ name: "turmas.edit", params: { turma_id: turma.id } });
    },
    async selectPlano() {
      this.loadForm = true;
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { id } = this.selectMonth;
        const periodo = this.periodoSelecionado.id;
        const response = await this.$services.planosBimestraisService.planoMensal(
          gestaoDeAulaId,
          id,
          periodo
        );

        this.loadForm = false;
        if (response.data !== "") {
          this.form.status = {
            descricao: null,
            id: null,
          };

          if (
            response.data.plano.status &&
            response.data.plano.status.descricao !== "Aguardando confirmação"
          ) {
            this.overlay = response.data.plano.status;
            this.overlayOpen = true;
          }
          this.form = response.data.plano;
          this.tematica = response.data.plano.tematica;
          this.bibliografia = response.data.plano.bibliografia;
          this.duvidasSelecionadas = response.data.plano.duvidas_id;
          this.objeto_conhecimento = response.data.plano.objeto_conhecimento;
          this.habilidadesSelecionadas = response.data.habilidades;
          // this.form.metodologiasSelecionadas = response.data.metodologias;
          this.metodologiasSelecionadas = response.data.plano.metodologias_id;
          this.recursosDeEnsinoSelecionados = response.data.plano.recursos_ensino_id;
          this.avaliacoesSelecionadas = response.data.plano.avaliacoes_id;
          this.recursosDeEnsinoSelecionadosOutros =
            response.data.plano.recursosDeEnsinoSelecionadosOutros;
          this.metodologiaOutros = response.data.plano.metodologiaOutros;
          this.avaliacoesSelecionadasOutros = response.data.plano.avaliacoesSelecionadasOutros;
          this.duvidasSelecionadasOutros = response.data.plano.duvidasSelecionadasOutros;
          return;
        }
        this.overlayOpen = false;

        this.habilidadesSelecionadas = [];
        this.metodologiasSelecionadas = [];
        this.recursosDeEnsinoSelecionados = [];
        this.avaliacoesSelecionadas = [];
        this.duvidasSelecionadas = [];
        this.habilidadesObjetivo = "";
        this.tematica = "";
        this.bibliografia = "";
        this.objeto_conhecimento = "";
        this.recursosDeEnsinoSelecionadosOutros = "";
        this.metodologiaOutros = "";
        this.avaliacoesSelecionadasOutros = "";
        this.duvidasSelecionadasOutros = "";
        this.form = {
          mes_id: id,
          periodo_id: periodo,
        };
      } catch (error) {
        this.$handleError(error);
      }
      this.loadForm = false;
    },

    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      this.formRequest = false;
      try {
        if (
          !this.tematica ||
          !this.objeto_conhecimento ||
          // !this.habilidadesSelecionadas ||
          !this.metodologiasSelecionadas ||
          !this.recursosDeEnsinoSelecionados ||
          !this.avaliacoesSelecionadas
        ) {
          this.formRequest = true;
          this.$loaderService.close();
          return;
        }
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.mes_id = this.selectMonth.id;
        this.form.periodo_id = this.periodoSelecionado.id;
        this.form.tematica = this.tematica;
        this.form.bibliografia = this.bibliografia;
        this.form.objeto_conhecimento = this.objeto_conhecimento;
        this.form.habilidadesObjetivo = this.habilidadesObjetivo;
        this.form.avaliacoesSelecionadasOutros = this.avaliacoesSelecionadasOutros;
        this.form.recursosDeEnsinoSelecionadosOutros = this.recursosDeEnsinoSelecionadosOutros;
        this.form.metodologiaOutros = this.metodologiaOutros;
        this.form.duvidasSelecionadasOutros = this.duvidasSelecionadasOutros;
        this.form.habilidadesSelecionadas = this.habilidadesSelecionadas.map((h) => h.id);
        this.form.habilidades_id = this.habilidadesSelecionadas.map((h) => parseInt(h.id, 10));

        if (this.metodologiasSelecionadas) {
          this.form.metodologias_id = this.metodologiasSelecionadas.map((m) => {
            if (!m.id) {
              return m;
            }
            return m.id;
          });
        }
        if (this.recursosDeEnsinoSelecionados) {
          this.form.recursos_ensino_id = this.recursosDeEnsinoSelecionados.map((m) => {
            if (!m.id) {
              return m;
            }
            return m.id;
          });
        }

        if (this.avaliacoesSelecionadas) {
          this.form.avaliacoes_id = this.avaliacoesSelecionadas.map((m) => {
            if (!m.id) {
              return m;
            }
            return m.id;
          });
        }

        if (this.duvidasSelecionadas) {
          this.form.duvidas_id = this.duvidasSelecionadas.map((m) => {
            if (!m.id) {
              return m;
            }
            return m.id;
          });
        }

        // console.log(this.form);
        // console.log("createOrUpdate");
        // console.log("envio final");
        await this.$services.planosBimestraisService.planoMensalCreateOrUpdate(this.form);
        this.$toast.success("Plano mensal salvo com sucesso!");
        // return;
        this.$router.push({ name: "gestoesDeAulas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      const { gestaoDeAulaId } = this.$route.params;
      const { mes_id } = this.form;
      const { periodo_id } = this.form;
      try {
        this.$loaderService.open("Carregando dados...");
        const response = await this.$services.gestoesDeAulasService.visualizarParaPlano(
          gestaoDeAulaId
        );
        // console.log("load");
        // console.log(response.data);

        if (response.gestoesDeAulas.turma.series) {
          response.gestoesDeAulas.turma.series = {
            ...response.gestoesDeAulas.turma.series.shift(),
          };
        } else {
          response.gestoesDeAulas.turma.series = {};
          this.dialogSerieVazia = true;
        }

        this.gestaoDeAula = response.gestoesDeAulas;
        this.meses = response.meses;
        this.periodos = response.periodos;
        this.metodologias = response.metodologias;
        this.recursosDeEnsino = response.recursos;
        this.avaliacoes = response.avaliacoes;
        this.duvidas = response.duvidas;
        const data = await this.$services.planosBimestraisService.planoMensal(
          gestaoDeAulaId,
          mes_id,
          periodo_id
        );
        if (data !== "") {
          if (
            data.data.plano?.status &&
            data.data.plano?.status?.descricao !== "Aguardando confirmação"
          ) {
            this.overlay = data.data.plano?.status;
            this.overlayOpen = true;
          }
          // this.form = data.data.plano; estava bugando muita esse trecho
          this.habilidadesSelecionadas = data.habilidades === "undefined" ? data.habilidades : [];
          this.metodologiasSelecionadas = data.metodologias;
          this.habilidadesObjetivo = data.plano?.habilidadesObjetivo;
          this.tematica = data.plano?.tematica;
          this.objeto_conhecimento = data.plano?.objeto_conhecimento;
          this.recursosDeEnsinoSelecionados = data.plano?.recursos_ensino_id;
          this.recursosDeEnsinoSelecionadosOutros = data.plano?.recursosDeEnsinoSelecionadosOutros;
          this.metodologiaOutros = data.plano?.recursosDeEnsinoSelecionadosOutros;
          this.avaliacoesSelecionadas = data.plano?.avaliacoes_id;
          this.duvidasSelecionadas = data.plano?.duvidas_id;
          this.bibliografia = data.plano?.bibliografia;
          this.duvidasSelecionadasOutros = data.plano?.duvidasSelecionadasOutros;
          this.avaliacoesSelecionadasOutros = data.plano?.avaliacoesSelecionadasOutros;
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async procurarHabilidade() {
      const codigo_disciplina = this.gestaoDeAula.disciplina.id;
      const dados = await this.$services.habilidadeService.procurarHabilidade(
        this.search,
        codigo_disciplina
      );
      this.habilidadesPesquisa = dados.data.data;
    },
    async verHistoricoPlanos() {
      this.openDrawer = !this.openDrawer;
    },
    setForm() {},
  },
};
</script>

<style></style>
