<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Mensal Infantil
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <!-- // Inicio cabeçalho -->
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="primary">
          <v-col cols="6">
            <v-chip class="white--text mb-3" color="blue" style="margin: 3px">
              <v-icon left> mdi-account-circle </v-icon>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
            </v-chip>
            <br />
            <v-chip color="white" class="blue--text" border style="margin: 3px">
              Serie:
              {{ gestaoDeAula.turma.series[0].descricao }}
            </v-chip>
            <v-chip color="white" class="blue--text" style="margin: 3px">
              Turma: {{ gestaoDeAula.turma.descricao }}
            </v-chip>
            <v-chip color="white" class="blue--text" style="margin: 3px">
              Disciplina: {{ gestaoDeAula.disciplina.descricao }}
            </v-chip>
            <v-chip color="white" class="blue--text" style="margin: 3px">
              Turno: {{ gestaoDeAula.turma.turno.descricao }}
            </v-chip>
          </v-col>
        </v-card>
      </v-col>
      <!-- // Fim cabeçalho -->
    </v-row>

    <e-overlay v-if="overlayOpen" :overlay="overlay" />
    <v-alert
      v-if="overlayOpen"
      :color="overlay.descricao?.toUpperCase() == 'PLANO INVÁLIDO' ? 'red' : 'green'"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-alert"
      class="mt-10"
      prominent
    >
      <v-col>
        Esse plano está com status de <strong>{{ overlay.descricao?.toUpperCase() }}</strong
        >, por isso não pode mais ser alterado! <br />
        Para mais informações, entre em contato com o coordenador.
      </v-col>
    </v-alert>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row class="pt-5">
          <v-col cols="12">
            <a
              href="https://drive.google.com/drive/folders/0B9sasL8AsTgjfjBIQ2h4MVdic0doS0RGdTZqTGhnQWxnSzd1RXhYM2hURzE5dEpTd2ZpNVE?resourcekey=0-iPEaI0tdqnnh_laG2DNOyA"
              target="_blank"
            >
              <v-btn color="blue" class="white--text">
                <v-icon class="mr-2">folder</v-icon>
                Documentos norteadores
              </v-btn>
            </a>
          </v-col>
        </v-row>

        <!-- experiencias -->
        <v-row>
          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">CAMPOS DE EXPERIÊNCIAS</label>
                <v-autocomplete
                  v-model="experienciasSelecionadas"
                  :items="experiencias"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
              </div>
            </v-card>
          </v-col>
          <!-- experiencias -->

          <v-col cols="12" lg="12">
            <v-card class="pa-md-4 mt-5">
              <label for="">CICLO DO PLANEJAMENTO</label>
              <v-select
                :items="meses"
                item-text="descricao"
                item-value="id"
                v-model="selectMonth"
                return-object
                label="Selecione um mês"
                outlined
                dense
              >
              </v-select>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="pa-md-4 mt-5">
              <label for="">PERÍODO LETIVO</label>
              <v-select
                class="mt-4"
                :items="periodos"
                item-text="descricao"
                item-value="id"
                v-model="periodoSelecionado"
                return-object
                label="Selecione"
                outlined
                dense
              >
              </v-select>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> CONTEÚDO | ASSUNTO | OBJETOS DE CONHECIMENTO</label>
                <v-textarea
                  rows="3"
                  autofocus
                  v-model="objetos_conhecimento"
                  placeholder="Digite Aqui"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-3 mt-5" color="blue" dark v-bind="attrs" v-on="on">
                  INSIRA O CÓDIGO DCTMA
                </v-btn>
              </template>
              <v-card>
                <v-toolbar dark color="blue">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>PESQUISA</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-col>
                  <v-row>
                    <v-col cols="12">
                      <v-sheet color="white" elevation="1">
                        <div class="py-2 px-4" color="white">
                          <v-text-field
                            v-model="search"
                            autofocus
                            v-on:keyup="() => procurarHabilidade()"
                            label="DIGITE O CODIGO DCTMA"
                          ></v-text-field>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-sheet color="white" elevation="1" rounded>
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            HABILIDADES ENCONTRADAS
                            <v-icon dark right> mdi-magnify </v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>
                        <div class="py-2 px-4">
                          <v-list rounded>
                            <v-list-item-group>
                              <v-list-item
                                class="grey lighten-3 mb-3 pa-md-4"
                                v-for="m in habilidadesPesquisa"
                                :key="m.id"
                              >
                                [ {{ m.codigo }} ] {{ m.descricao }}
                                <v-btn
                                  @click="() => selecionarHabilidade(m)"
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="blue"
                                >
                                  <v-icon dark> mdi-plus </v-icon>
                                </v-btn>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-sheet>
                    </v-col>

                    <v-col cols="6">
                      <v-sheet color="white" elevation="1">
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            Habilidades selecionados
                            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>
                        <div class="py-2 px-4">
                          <v-list rounded>
                            <v-list-item-group>
                              <v-list-item
                                class="grey lighten-3 mb-3 pa-md-4"
                                v-for="(m, index) in habilidadesSelecionadas"
                                :key="m.id"
                              >
                                <v-btn
                                  @click="() => deletarHabilidade(index)"
                                  class="mx-2"
                                  fab
                                  dark
                                  small
                                  color="red"
                                >
                                  <v-icon dark> mdi-delete </v-icon>
                                </v-btn>
                                {{ m.codigo }} - {{ m.descricao }}
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-dialog>

            <v-card class="pa-md-4">
              <div>
                <label for=""> OBJETIVOS DE APRENDIZAGEM E DESENVOLVIMENTO </label>
                <v-list rounded>
                  <v-list-item-group color="primary">
                    <p v-if="habilidadesSelecionadas.length === 0">(Nenhum codigo adicionado)</p>
                    <v-list-item
                      class="grey lighten-3 mb-3 pa-md-4"
                      v-for="(m, index) in habilidadesSelecionadas"
                      :key="m.id"
                    >
                      <v-btn
                        @click="() => deletarHabilidade(index)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                      {{ m.codigo }} - {{ m.descricao }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">POSSIBILIDADES DE EXPERIÊNCIAS</label>
                <v-textarea
                  rows="3"
                  v-model="possibilidadesDeExperiencia"
                  placeholder="Digite aqui"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">RECURSOS DE ENSINO</label>
                <v-autocomplete
                  v-model="recursos_selecionados"
                  :items="recursos"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                >
                </v-autocomplete>

                <v-textarea
                  rows="3"
                  v-model="recursos_selecionados_outros"
                  label="Digite aqui caso precise informar outros recursos"
                >
                </v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> AVALIAÇÃO </label>
                <v-textarea rows="3" v-model="avaliacao" placeholder="Digite aqui"></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for=""> BIBLIOGRAFIA </label>
                <v-textarea rows="3" v-model="bibliografia" placeholder="Digite aqui"></v-textarea>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="pa-md-4">
              <div>
                <label for="">TIRA-DÚVIDAS ONLINE</label>
                <v-autocomplete
                  v-model="duvidasSelecionadas"
                  :items="duvidas"
                  attach
                  chips
                  label="Marque uma ou mais opções"
                  multiple
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
                <v-textarea
                  rows="3"
                  v-model="duvidasSelecionadasOutros"
                  label="Digite aqui caso precise informar outras opções"
                ></v-textarea>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm || overlayOpen"
              :loading="submittingForm"
              type="submit"
            >
              Enviar Dados
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
    <v-alert
      v-show="formRequest"
      class="my-2 animate__animated animate__headShake"
      dense
      border="left"
      type="warning"
    >
      Preencha todo o questionário
    </v-alert>
  </main-template>
</template>

<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  watch: {
    form() {
      this.formRequest = false;
    },
    // eslint-disable-next-line
    "form.status": function () {
      this.overlayOpen = false;
    },
    selectMonth() {
      this.selectPlano();
    },
    periodoSelecionado() {
      this.selectPlano();
    },
    overlay(val) {
      if (val?.descricao === "Aguardando confirmação" || val?.descricao === "") {
        this.overlayOpen = false;
      } else {
        this.overlayOpen = true;
      }
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      dialog: false,
      menu: false,
      modal: false,
      search: "",
      avaliacao: "",
      bibliografia: "",
      objetos_conhecimento: "",
      possibilidadesDeExperiencia: "",
      recursos_selecionados_outros: "",
      duvidasSelecionadasOutros: "",
      meses: [],
      periodos: [],
      experiencias: [],
      experienciasSelecionadas: [],
      recursos: [],
      recursos_selecionados: [],
      duvidas: [],
      duvidasSelecionadas: [],
      habilidades: [],
      habilidadesPesquisa: [],
      habilidadesSelecionadas: [],
      submittingForm: false,
      formRequest: false,
      loadForm: false,
      progress: 90,
      tab_atual: 0,
      overlay: {},
      overlayOpen: false,
      periodoSelecionado: {
        id: 1,
        descricao: "Periodo 1",
      },
      form: {
        mes_id: 1,
        periodo_id: 1,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      selectMonth: {
        id: 1,
        descricao: "Janeiro",
      },
    };
  },
  methods: {
    async deletarHabilidade(index) {
      this.habilidadesSelecionadas.splice(index, 1);
    },
    async selectPlano() {
      this.loadForm = true;

      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { id } = this.selectMonth;
        const periodo = this.periodoSelecionado.id;

        const data = await this.$services.planoMensalInfantilService.planoMensal(
          gestaoDeAulaId,
          periodo,
          id
        );

        if (data !== "") {
          if (data[0].status && data[0].status?.descricao !== "Aguardando confirmação") {
            this.overlay = data[0].status;
            this.overlayOpen = true;
          }

          /* eslint-disable */
          this.form = data[0] ?? null;
          this.experienciasSelecionadas = JSON.parse(data[0].experienciasSelecionadas);
          this.objetos_conhecimento = data[0].objetos_conhecimento;
          this.possibilidadesDeExperiencia = data[0].possibilidadesDeExperiencia;
          this.recursos_selecionados = JSON.parse(data[0].recursos_selecionados);
          this.recursos_selecionados_outros = data[0].recursos_selecionados_outros;
          this.duvidasSelecionadas = JSON.parse(data[0].duvidasSelecionadas);
          this.duvidasSelecionadasOutros = data[0].duvidasSelecionadasOutros;
          this.avaliacao = data[0].avaliacao;
          this.bibliografia = data[0].bibliografia;
          /* eslint-disable */
          this.habilidadesSelecionadas = data[1];
          return;
        }

        this.overlayOpen = false;

        this.habilidadesSelecionadas = [];
        this.experienciasSelecionadas = [];
        this.avaliacoesSelecionadas = [];
        this.duvidasSelecionadas = [];
        this.habilidadesObjetivo = "";
        this.bibliografia = "";
        this.avaliacao = "";
        this.objetos_conhecimento = "";
        this.recursos_selecionados_outros = "";
        this.recursos_selecionados = [];
        this.possibilidadesDeExperiencia = "";
        this.duvidasSelecionadasOutros = "";
        this.dates = [];
        this.form = {
          periodo_id: periodo,
          mes_id: id,
        };
      } catch (error) {
        this.$handleError(error);
      }
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      this.formRequest = false;

      try {
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.periodoSelecionado = this.periodoSelecionado.id;
        this.form.avaliacao = this.avaliacao;
        this.form.bibliografia = this.bibliografia;
        this.form.objetos_conhecimento = this.objetos_conhecimento;
        this.form.possibilidadesDeExperiencia = this.possibilidadesDeExperiencia;
        this.form.ciclosDoPlanejamento = this.selectMonth.id;
        this.form.recursos_selecionados_outros = this.recursos_selecionados_outros;
        this.form.duvidasSelecionadasOutros = this.duvidasSelecionadasOutros;
        this.form.experienciasSelecionadas = this.experienciasSelecionadas.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.recursos_selecionados = this.recursos_selecionados.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.duvidasSelecionadas = this.duvidasSelecionadas.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });
        this.form.habilidadesSelecionadas = this.habilidadesSelecionadas.map((m) => {
          if (!m.id) {
            return m;
          }
          return m.id;
        });

        await this.$services.planoMensalInfantilService.planoMensalInfantilCreateOrUpdate(
          this.form
        );
        this.$toast.success("Plano mensal salvo com sucesso!");
        this.$router.push({ name: "gestoesDeAulas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      const { gestaoDeAulaId } = this.$route.params;
      const { mes_id } = this.form;
      const { periodo_id } = this.form;

      try {
        this.$loaderService.open("Carregando dados...");

        const response = await this.$services.planoMensalInfantilService.visualizarParaPlano(
          gestaoDeAulaId
        );

        this.gestaoDeAula = response.gestoesDeAulas;
        this.meses = response.meses;
        this.periodos = response.periodos;
        this.experiencias = response.experiencias;
        this.recursos = response.recursos;
        this.duvidas = response.duvidas;

        const data = await this.$services.planoMensalInfantilService.planoMensal(
          gestaoDeAulaId,
          periodo_id,
          mes_id
        );

        if (!data) {
          this.$loaderService.close();
          return;
        }

        /* eslint-disable */
        this.form = data[0] ?? null;
        this.experienciasSelecionadas = JSON.parse(data[0].experienciasSelecionadas);
        this.objetos_conhecimento = data[0].objetos_conhecimento;
        this.possibilidadesDeExperiencia = data[0].possibilidadesDeExperiencia;
        this.recursos_selecionados = JSON.parse(data[0].recursos_selecionados);
        this.recursos_selecionados_outros = data[0].recursos_selecionados_outros;
        this.duvidasSelecionadas = JSON.parse(data[0].duvidasSelecionadas);
        this.duvidasSelecionadasOutros = data[0].duvidasSelecionadasOutros;
        this.avaliacao = data[0].avaliacao;
        this.bibliografia = data[0].bibliografia;
        this.overlay = data[0].status;
        /* eslint-disable */
        this.habilidadesSelecionadas = data[1];
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async procurarHabilidade() {
      const codigo_disciplina = this.gestaoDeAula.disciplina.id;
      const dados = await this.$services.habilidadeService.procurarHabilidade(
        this.search,
        codigo_disciplina
      );
      this.habilidadesPesquisa = dados.data.data;
    },
    selecionarHabilidade(m) {
      const habilidade = this.habilidadesSelecionadas.find((h) => h.id === m.id);
      if (habilidade) return;
      this.habilidadesSelecionadas.push(m);
    },
    setForm() {},
  },
};
</script>

<style></style>
