<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Circuito de Notas </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between pa-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"> Criar Circuito </v-btn>
            </template>
            <v-list class="pt-0">
              <v-list-item class="pa-0">
                <v-btn width="100%" @click="criarCircuito" text>Novo Circuito</v-btn>
              </v-list-item>
              <v-list-item class="pa-0">
                <v-btn width="100%" @click="importarCircuito" text>Importar Circuito</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="circuitosList"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="circuito.estaDeletado ? ['deleted'] : []"
                v-for="circuito of items"
                :key="circuito.id"
              >
                <td>{{ circuito.id }}</td>
                <td>{{ circuito.ano ? circuito.ano.descricao : "- - -" }}</td>
                <td>{{ circuito.descricao }}</td>
                <!-- <td>{{ circuito.contexto }}</td> -->
                <td>
                  <v-btn x-small @click="importarNotasAntigas(circuito.id)">
                    <v-icon>import_export</v-icon></v-btn
                  >
                  <v-tooltip v-if="!circuito.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarCircuito(circuito)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar circuito</span>
                  </v-tooltip>
                  <v-tooltip v-if="!circuito.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => vincularEtapas(circuito)"
                      >
                        <v-icon small>fa fa-table</v-icon>
                      </v-btn>
                    </template>
                    <span>Configurações do Circuito</span>
                  </v-tooltip>
                  <v-tooltip v-if="!circuito.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarCircuito(circuito)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar circuito</span>
                  </v-tooltip>
                  <v-tooltip v-if="circuito.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarCircuito(circuito)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar circuito</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="350px">
          <v-card>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-card-title>
                  <span class="text-h5">Selecione um Circuito</span>
                </v-card-title>
                <v-card-text>
                  <ValidationProvider name="Circuito de Nota" rules="required" v-slot="{ errors }">
                    <e-autocomplete
                      :items="anos"
                      :return-object="false"
                      :item-text="(circuito) => circuito.descricao"
                      :item-value="(circuito) => circuito.id"
                      :error-messages="errors"
                      v-model="anoId"
                      @change="changeAno(anoId)"
                      label="Selecione uma ano"
                      dense
                      solo
                    />
                  </ValidationProvider>
                  <ValidationProvider name="Circuito de Nota" rules="required" v-slot="{ errors }">
                    <e-autocomplete
                      v-show="selectCircuitos"
                      :items="circuitosDisponiveis"
                      :return-object="false"
                      :item-text="(circuito) => circuito.descricao"
                      :item-value="(circuito) => circuito.id"
                      :error-messages="errors"
                      v-model="circuito_id"
                      label="Selecione um circuito"
                      dense
                      solo
                    />
                  </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialog = false"> Cancelar </v-btn>
                  <v-btn
                    color="green"
                    text
                    :disabled="submittingForm"
                    :loading="submittingForm"
                    type="submit"
                  >
                    Importar
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      circuitosList: [],
      circuitosListGeral: [],
      circuito_id: null,
      submittingForm: false,
      circuitos: [
        {
          id: 19,
          descricao: "circuito 1",
        },
      ],
      anoId: null,
      dialog: false,
      dataTableFilter,
      search: "",
      circuitosDisponiveis: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Ano", value: "ano" },
          { text: "Nome", value: "descricao" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
      anos: [],
      selectCircuitos: false,
    };
  },
  mounted() {
    this.loadCircuitosGeral();
    this.loadCircuitos();
    this.getAnos();
  },
  watch: {
    carregandoCircuitos(value) {
      if (value && this.circuitosList.length === 0) {
        this.$loaderService.open("Carregando Circuitos");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    async importarNotasAntigas(circuitoId) {
      this.$toast.info("Copiando notas...");
      try {
        const data = await this.$services.circuitoNotasService.importarNotasAntigas(circuitoId);
        this.$toast.success(data);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async loadCircuitosGeral() {
      try {
        const data = await this.$services.circuitoNotasService.syncAllGeral();
        this.circuitosListGeral = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async loadCircuitos() {
      try {
        const data = await this.$services.circuitoNotasService.syncAll();
        this.circuitosList = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    criarCircuito() {
      this.$router.push({ name: "circuito-notas.create" });
    },
    editarCircuito(circuito) {
      this.$router.push({ name: "circuito-notas.edit", params: { circuito_id: circuito.id } });
      // return circuito;
    },
    vincularEtapas(circuito) {
      this.$router.push({ name: "circuito-notas.etapas", params: { circuito_id: circuito.id } });
      // return circuito;
    },
    showCircuito(circuito) {
      this.$router.push({ name: "circuito-notas.show", params: { circuito_id: circuito.id } });
      // return circuito;
    },
    async deletarCircuito(circuito) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta circuito?",
        confirmationCode: circuito.id,
        confirmationMessage: `Por favor, digite <strong>${circuito.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Circuito");
              try {
                await this.$services.circuitoNotasService.deletarCircuito(circuito);
                this.$toast.success("Circuito deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    importarCircuito() {
      this.dialog = true;
    },
    recuperarCircuito(circuito) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta circuito?",
        confirmationCode: circuito.id,
        confirmationMessage: `Por favor, digite <strong>${circuito.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Circuito");
              try {
                await this.$services.circuitosService.recuperarCircuito(circuito);
                this.$toast.success("Circuito recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        await this.$services.circuitoNotasService.importarCircuito(this.circuito_id);

        this.$store.dispatch("CircuitoNotas/loadCircuitos");
        this.$toast.success("Circuito Importado com sucesso");
        this.dialog = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async getAnos() {
      try {
        const data = await this.$services.anosService.syncAll();
        this.anos = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async changeAno(anoId) {
      this.selectCircuitos = true;
      this.circuitosDisponiveis = this.circuitosListGeral.filter((d) => d.ano_id === anoId);
    },
  },
};
</script>

<style></style>
