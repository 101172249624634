<template>
  <v-expansion-panels focusable>
    <v-expansion-panel active-class="yellow lighten-5">
      <v-expansion-panel-header class="titleBimestre py-0">
        <template>
          <v-row no-gutters>
            <v-col cols="4"> Valor da Média para Aprovado </v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <!-- <span v-if="open"> Insira um valor para nota abaixo da média e acima da média</span> -->
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
            <div class="d-flex justify-space-between mt-3 mb-2">
              <th class="text-left">Entre</th>
              <th class="text-left">E</th>
              <th class="text-left">Cor</th>
              <th class="text-left">Texto de Apresentação</th>
            </div>
            <v-divider />
            <div class="d-flex justify-space-between mt-3">
              <div>
                <v-currency-field
                  v-model="valor_inicial_aprovado"
                  label="Valor"
                  locale="pt-BR"
                  currency-symbol="R$"
                  :min="0"
                  outlined
                ></v-currency-field>
              </div>
              <div>
                <v-currency-field
                  v-model="valor_final_aprovado"
                  label="Valor"
                  locale="pt-BR"
                  currency-symbol="R$"
                  :min="0"
                  outlined
                ></v-currency-field>
              </div>
              <div class="d-flex justify-center">
                <template>
                  <v-menu
                    v-model="menuAprovado"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleAprovado" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker flat v-model="colorAprovado"></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </div>
              <div>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="formAprovado.aprovado_texto"
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn @click="salvarAprovado()" outlined color="green"> Salvar Alterações </v-btn>
            </div>
          </form>
        </ValidationObserver>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="yellow lighten-5">
      <v-expansion-panel-header class="titleBimestre py-0">
        <template>
          <v-row no-gutters>
            <v-col cols="4"> Valor da Média para Reprovado </v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <!-- <span v-if="open"> Insira um valor para nota abaixo da média e acima da média</span> -->
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
            <div class="d-flex justify-space-between mt-3 mb-2">
              <th class="text-left">Entre</th>
              <th class="text-left">E</th>
              <th class="text-left">Cor</th>
              <th class="text-left">Texto de Apresentação</th>
            </div>
            <v-divider />
            <div class="d-flex justify-space-between mt-3">
              <div>
                <v-currency-field
                  v-model="valor_inicial_reprovado"
                  label="Valor"
                  locale="pt-BR"
                  currency-symbol="R$"
                  :min="0"
                  outlined
                ></v-currency-field>
              </div>
              <div>
                <v-currency-field
                  v-model="valor_final_reprovado"
                  label="Valor"
                  locale="pt-BR"
                  currency-symbol="R$"
                  :min="0"
                  outlined
                ></v-currency-field>
              </div>
              <div class="d-flex">
                <template>
                  <v-menu
                    v-model="menuReprovado"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleReprovado" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker flat v-model="colorReprovado"></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </div>
              <div>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="formReprovado.reprovado_texto"
                  @change="salvarReprovado()"
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn @click="salvarReprovado()" outlined color="green"> Salvar Alterações </v-btn>
            </div>
          </form>
        </ValidationObserver>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="yellow lighten-5">
      <v-expansion-panel-header class="titleBimestre py-0">
        <template>
          <v-row no-gutters>
            <v-col cols="4"> Valor da Média para Recuperação </v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <!-- <span v-if="open"> Insira um valor para nota abaixo da média e acima da média</span> -->
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
            <div class="d-flex justify-space-between mt-3 mb-2">
              <th class="text-left">Entre</th>
              <th class="text-left">E</th>
              <th class="text-left">Cor</th>
              <th class="text-left">Texto de Apresentação</th>
            </div>
            <v-divider />
            <div class="d-flex justify-space-between mt-3">
              <div>
                <v-currency-field
                  v-model="valor_inicial_recuperacao"
                  label="Valor"
                  locale="pt-BR"
                  currency-symbol="R$"
                  :min="0"
                  outlined
                ></v-currency-field>
              </div>
              <div>
                <v-currency-field
                  v-model="valor_final_recuperacao"
                  label="Valor"
                  locale="pt-BR"
                  currency-symbol="R$"
                  :min="0"
                  outlined
                ></v-currency-field>
              </div>
              <div class="d-flex">
                <template>
                  <v-menu
                    v-model="menuRecuperacao"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleRecuperacao" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="colorRecuperacao" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </div>
              <div>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="formRecuperacao.recuperacao_texto"
                  @change="salvarRecuperacao()"
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn @click="salvarRecuperacao()" outlined color="green"> Salvar Alterações </v-btn>
            </div>
          </form>
        </ValidationObserver>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "configuracoes-media",
  props: {
    circuitoId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    valor_inicial_aprovado: null,
    valor_inicial_recuperacao: null,
    valor_inicial_reprovado: null,
    valor_final_aprovado: null,
    valor_final_recuperacao: null,
    valor_final_reprovado: null,
    colorAprovado: "#1063AF",
    colorReprovado: "#1063AF",
    colorRecuperacao: "#1063AF",
    dialog: false,
    submittingForm: false,
    date: null,
    mask: "!#XXXXXXXX",
    menuRecuperacao: false,
    menuReprovado: false,
    menuAprovado: false,
    circuitoDados: [],
    currencyMask: [
      // Definindo mask como array
      /\d/,
      /\d/,
      ",",
      /\d/,
      /\d/,
    ],
  }),
  computed: {
    formAprovado() {
      this.getDadosCircuito();
      const circuito = this.circuitoDados;
      return { ...circuito };
    },
    formReprovado() {
      this.getDadosCircuito();
      const circuito = this.circuitoDados;
      return { ...circuito };
    },
    formRecuperacao() {
      this.getDadosCircuito();
      const circuito = this.circuitoDados;
      return { ...circuito };
    },
    swatchStyleReprovado() {
      const { colorReprovado, menuReprovado } = this;
      return {
        backgroundColor: colorReprovado,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menuReprovado ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    swatchStyleAprovado() {
      const { colorAprovado, menuAprovado } = this;
      return {
        backgroundColor: colorAprovado,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menuAprovado ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    swatchStyleRecuperacao() {
      const { colorRecuperacao, menuRecuperacao } = this;
      return {
        backgroundColor: colorRecuperacao,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menuRecuperacao ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  mounted() {
    this.loadCircuito();
  },
  methods: {
    async loadCircuito() {
      const circuito = await this.$services.circuitoNotasService.getCircuito(
        parseInt(this.$route.params.circuito_id, 10)
      );
      this.circuitoDados = circuito;
    },
    async salvarAprovado() {
      this.submitFormAprovado();
    },
    async salvarReprovado() {
      this.submitFormReprovado();
    },
    async salvarRecuperacao() {
      this.submitFormRecuperacao();
    },
    async getDadosCircuito() {
      this.valor_inicial_aprovado = this.circuitoDados.valor_inicial_aprovado;
      this.valor_inicial_recuperacao = this.circuitoDados.valor_inicial_recuperacao;
      this.valor_inicial_reprovado = this.circuitoDados.valor_inicial_reprovado;
      this.valor_final_aprovado = this.circuitoDados.valor_final_aprovado;
      this.valor_final_recuperacao = this.circuitoDados.valor_final_recuperacao;
      this.valor_final_reprovado = this.circuitoDados.valor_final_reprovado;
    },
    async submitFormAprovado() {
      this.submittingForm = true;
      this.formAprovado.id = this.circuitoId;
      this.formAprovado.aprovado_cor = this.colorAprovado;
      this.formAprovado.valor_inicial_aprovado = this.valor_inicial_aprovado;
      this.formAprovado.valor_final_aprovado = this.valor_final_aprovado;
      try {
        await this.$services.circuitoNotasService.atualizarCircuito(this.formAprovado);
        this.loadCircuito();
        this.$toast.success("Dados salvos com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async submitFormReprovado() {
      this.submittingForm = true;
      this.formReprovado.id = this.circuitoId;
      this.formReprovado.reprovado_cor = this.colorReprovado;
      this.formReprovado.valor_inicial_reprovado = this.valor_inicial_reprovado;
      this.formReprovado.valor_final_reprovado = this.valor_final_reprovado;
      try {
        await this.$services.circuitoNotasService.atualizarCircuito(this.formReprovado);
        this.loadCircuito();
        this.$toast.success("Dados salvos com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async submitFormRecuperacao() {
      this.submittingForm = true;
      this.formRecuperacao.id = this.circuitoId;
      this.formRecuperacao.recuperacao_cor = this.colorRecuperacao;
      this.formRecuperacao.valor_inicial_recuperacao = this.valor_inicial_recuperacao;
      this.formRecuperacao.valor_final_recuperacao = this.valor_final_recuperacao;
      try {
        await this.$services.circuitoNotasService.atualizarCircuito(this.formRecuperacao);
        this.loadCircuito();
        this.$toast.success("Dados salvos com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>
