<template>
  <v-col sm="12" xs="12" cols="12" md="4">
    <v-card :loading="loading" class="pt-2" height="250">
      <div class="ma-5">
        <h2>Aprovados e Reprovados</h2>
      </div>
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <apexchart height="150" :options="chartOptions" :series="series"></apexchart>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "ZonaCardNota",
  mounted() {
    this.getZona2();
  },
  props: {
    turmaId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    turmaId() {
      console.log("opa mudou turma");
      this.getZona2ByTurma(this.turmaId);
    },
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      series: [44, 55],
      chartOptions: {
        chart: {
          height: 400,
          type: "donut",
          // offsetY: -20,
          offsetX: -20,
        },

        legend: {
          // show: true,
          // position: "right",
          // offsetY: -5,
          offsetX: -20,
        },

        labels: ["Aprovado", "Reprovado"],

        responsive: [
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 400,
              },
              legend: {
                position: "bottom",
                offsetY: -80,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    async getZona2() {
      const response = await this.$services.alunosService.zonaNota();
      const payload = Object.values(response.data);
      console.log("payload: ", payload);
      this.loading = false;
      this.series = [];
      this.series.push(parseInt(payload[0], 10));
      this.series.push(parseInt(payload[1], 10));
    },
    async getZona2ByTurma(id) {
      const response = await this.$services.alunosService.zonaNotaByTurma(id);
      const payload = Object.values(response.data);
      console.log("payload nota: ", payload);
      this.loading = false;
      this.series = [];
      this.series.push(parseInt(payload[0], 10));
      this.series.push(parseInt(payload[1], 10));
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
