<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-card v-if="turma && disciplina && professor && matriculas">
          <v-card-title>Lançando notas</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <!-- <h4>Disciplina: {{ disciplina.descricao }}</h4>
            <h4>Professor: {{ professor.nome }}</h4> -->
            <h4>Qtd. alunos: {{ matriculas.length }}</h4>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-tabs v-model="tab_atual" background-color="primary" dark>
            <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">
              {{ tab.nome }}
            </v-tab>
          </v-tabs>
          <v-card-text>
            <v-simple-table v-if="matriculas" class="elevation-1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">N º</th>
                    <th class="text-left" width="300px">Aluno</th>
                    <th class="text-left" v-show="tab_atual == 0">Nota 1</th>
                    <th class="text-left" v-show="tab_atual == 0">Nota 2</th>
                    <th class="text-left" v-show="tab_atual == 0">Média 1</th>
                    <th class="text-left" v-show="tab_atual == 1">Nota 3</th>
                    <th class="text-left" v-show="tab_atual == 1">Nota 4</th>
                    <th class="text-left" v-show="tab_atual == 1">Média 2</th>
                    <th class="text-left" v-show="tab_atual == 2">Média 1</th>
                    <th class="text-left" v-show="tab_atual == 2">Média 2</th>
                    <th class="text-left" v-show="tab_atual == 2">Méd. Sem.</th>
                    <th class="text-left" v-show="tab_atual == 2">Rec 1</th>
                    <th class="text-left" v-show="tab_atual == 2">Resultado</th>
                    <th class="text-left" v-show="tab_atual == 3">Nota 5</th>
                    <th class="text-left" v-show="tab_atual == 3">Nota 6</th>
                    <th class="text-left" v-show="tab_atual == 3">Média 3</th>
                    <th class="text-left" v-show="tab_atual == 4">Nota 7</th>
                    <th class="text-left" v-show="tab_atual == 4">Nota 8</th>
                    <th class="text-left" v-show="tab_atual == 4">Média 4</th>
                    <th class="text-left" v-show="tab_atual == 5">Média 3</th>
                    <th class="text-left" v-show="tab_atual == 5">Média 4</th>
                    <th class="text-left" v-show="tab_atual == 5">Méd. Sem.</th>
                    <th class="text-left" v-show="tab_atual == 5">Rec 2</th>
                    <th class="text-left" v-show="tab_atual == 5">Resultado</th>
                    <th class="text-left" v-show="tab_atual == 6">Média 1</th>
                    <th class="text-left" v-show="tab_atual == 6">Média 2</th>
                    <th class="text-left" v-show="tab_atual == 6">Rec 1</th>
                    <th class="text-left" v-show="tab_atual == 6">Média 3</th>
                    <th class="text-left" v-show="tab_atual == 6">Média 4</th>
                    <th class="text-left" v-show="tab_atual == 6">Rec 2</th>
                    <th class="text-left" v-show="tab_atual == 6">Nota final</th>
                    <th class="text-left" v-show="tab_atual == 6">Prova final</th>
                    <th class="text-left" v-show="tab_atual == 6">Resultado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="matricula of matriculas"
                    :key="matricula.id"
                    :class="
                      $constants.situacoesMatriculaCondition[
                        removerEspaco(matricula.situacao ? matricula.situacao : '')
                      ]
                        ? 'red lighten-4'
                        : null
                    "
                  >
                    <td>{{ matriculas.indexOf(matricula) + 1 }}</td>
                    <td width="300px">
                      {{ matricula.aluno.nomecompleto }}

                      <v-chip
                        label
                        color="error"
                        small
                        v-if="
                          $constants.situacoesMatriculaCondition[
                            removerEspaco(matricula.situacao ? matricula.situacao : '')
                          ]
                        "
                        >{{ matricula.situacao }}</v-chip
                      >
                    </td>
                    <td v-show="tab_atual == 0">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].am_1"
                        @change="calcularMedia1(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 0">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].ab_1"
                        @change="calcularMedia1(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 0">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_1"
                      />
                    </td>
                    <td v-show="tab_atual == 1">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].am_2"
                        @change="calcularMedia2(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 1">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].ab_2"
                        @change="calcularMedia2(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 1">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_2"
                      />
                    </td>
                    <td v-show="tab_atual == 2">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_1"
                      />
                    </td>
                    <td v-show="tab_atual == 2">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_2"
                      />
                    </td>
                    <td v-show="tab_atual == 2">
                      <e-situacao-nota
                        :value="
                          calcularMedia(notas[matricula.id].media_1, notas[matricula.id].media_2)
                        "
                      />
                    </td>
                    <td v-show="tab_atual == 2">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        :disabled="
                          calcularMedia(notas[matricula.id].media_1, notas[matricula.id].media_2) >=
                          NOTA_MINIMA
                        "
                        v-model="notas[matricula.id].rec_1"
                      />
                    </td>
                    <td v-show="tab_atual == 2" class="text-center">
                      <e-resultado-semestral
                        :media1="notas[matricula.id].media_1"
                        :media2="notas[matricula.id].media_2"
                        :recuperacao="notas[matricula.id].rec_1"
                        :matricula="matricula"
                      />
                    </td>
                    <td v-show="tab_atual == 3">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].am_3"
                        @change="calcularMedia3(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 3">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].ab_3"
                        @change="calcularMedia3(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 3">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_3"
                      />
                    </td>
                    <td v-show="tab_atual == 4">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].am_4"
                        @change="calcularMedia4(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 4">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        v-model="notas[matricula.id].ab_4"
                        @change="calcularMedia4(notas[matricula.id])"
                      />
                    </td>
                    <td v-show="tab_atual == 4">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_4"
                      />
                    </td>
                    <td v-show="tab_atual == 5">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_3"
                      />
                    </td>
                    <td v-show="tab_atual == 5">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_4"
                      />
                    </td>
                    <td v-show="tab_atual == 5">
                      <e-situacao-nota
                        :value="
                          calcularMedia(notas[matricula.id].media_3, notas[matricula.id].media_4)
                        "
                      />
                    </td>
                    <td v-show="tab_atual == 5">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        :disabled="
                          calcularMedia(notas[matricula.id].media_3, notas[matricula.id].media_4) >=
                          NOTA_MINIMA
                        "
                        v-model="notas[matricula.id].rec_2"
                      />
                    </td>
                    <td v-show="tab_atual == 5" class="text-center">
                      <e-resultado-semestral
                        :media1="notas[matricula.id].media_3"
                        :media2="notas[matricula.id].media_4"
                        :recuperacao="notas[matricula.id].rec_2"
                        :matricula="matricula"
                      />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_1"
                      />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_2"
                      />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-situacao-nota :matricula="matricula" :value="notas[matricula.id].rec_1" />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_3"
                      />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].media_4"
                      />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-situacao-nota :matricula="matricula" :value="notas[matricula.id].rec_2" />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-situacao-nota
                        :matricula="matricula"
                        :value="notas[matricula.id].nota_final"
                      />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-nota-input
                        :matricula="matricula"
                        @input="() => atualizarNotaFinal(matricula.id)"
                        :disabled="notas[matricula.id].nota_final >= NOTA_MINIMA"
                        v-model="notas[matricula.id].rec_3"
                      />
                    </td>
                    <td v-show="tab_atual == 6">
                      <e-resultado-anual :matricula="matricula" :notas="notas[matricula.id]" />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-expand-transition>
              <div class="d-flex justify-end mt-2" v-if="temAlteracoesNaoSalvas">
                <span class="font-weight-bold warning--text"
                  >Você tem algumas notas não salvas...</span
                >
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn color="success" :loading="promiseCount > 0" block @click="salvarNotas"
          >Enviar Notas</v-btn
        >
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
const TIMEOUT = 30000;
const NOTA_MINIMA = 6;
export default {
  mounted() {
    this.loadData();
    setTimeout(() => this.handleChanges(), TIMEOUT);
    window.addEventListener("beforeunload", (event) => this.onCloseWindow(event));
  },
  data() {
    return {
      NOTA_MINIMA,
      notas: null,
      disciplina: null,
      turma: null,
      professor: null,
      matriculas: null,
      tab_atual: 0,
      tabs: [
        {
          id: 0,
          nome: "Bimestre 1",
        },
        {
          id: 1,
          nome: "Bimestre 2",
        },
        {
          id: 2,
          nome: "Resultado Bimestral",
        },
        {
          id: 3,
          nome: "Bimestre 3",
        },
        {
          id: 4,
          nome: "Bimestre 4",
        },
        {
          id: 5,
          nome: "Resultado Bimestral",
        },
        {
          id: 6,
          nome: "Resultado Final",
        },
      ],
      promiseCount: 0,
      temAlteracoesNaoSalvas: false,
    };
  },
  methods: {
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        const payload = await this.$services.notasService.get(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.notas = payload.notas;
        this.disciplina = payload.disciplina;
        this.turma = payload.turma;
        this.professor = payload.professor;
        this.matriculas = payload.matriculas;
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    verificarSituacao() {
      return true;
    },

    calcularMedia(nota1, nota2) {
      const media =
        ((nota1 ? parseFloat(nota1, 10) : 0) + (nota2 ? parseFloat(nota2, 10) : 0)) / 2.0;
      return Math.trunc(media * 10) / 10.0;
    },
    calcularMedia1(notas) {
      notas.media_1 =
        this.estaVazio(notas.am_1) && this.estaVazio(notas.ab_1)
          ? null
          : this.calcularMedia(notas.am_1, notas.ab_1);
    },
    calcularMedia2(notas) {
      notas.media_2 =
        this.estaVazio(notas.am_2) && this.estaVazio(notas.ab_2)
          ? null
          : this.calcularMedia(notas.am_2, notas.ab_2);
    },
    calcularMedia3(notas) {
      notas.media_3 =
        this.estaVazio(notas.am_3) && this.estaVazio(notas.ab_3)
          ? null
          : this.calcularMedia(notas.am_3, notas.ab_3);
    },
    calcularMedia4(notas) {
      notas.media_4 =
        this.estaVazio(notas.am_4) && this.estaVazio(notas.ab_4)
          ? null
          : this.calcularMedia(notas.am_4, notas.ab_4);
    },
    async salvarNotas() {
      if (!this.temAlteracoesNaoSalvas) {
        this.$toast.info("Todas as notas estão salvas no sistema 😁");
        return null;
      }
      this.$toast.info("Salvando notas...");
      this.promiseCount += 1;
      let response;
      try {
        response = await this.$services.notasService.salvarNotas(
          parseInt(this.$route.params.gestaoDeAulasId, 10),
          this.notas
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.promiseCount -= 1;
      if (this.promiseCount === 0) {
        this.temAlteracoesNaoSalvas = false;
      }
      return response;
    },
    async handleChanges() {
      if (this.$route.name !== "notas.lancarNotas") return;
      setTimeout(() => this.handleChanges(), TIMEOUT);
      try {
        await this.salvarNotas();
      } catch (error) {
        this.$handleError(error);
      }
    },
    atualizarNotaFinal(matriculaId) {
      this.temAlteracoesNaoSalvas = true;
      setTimeout(() => {
        const notasObject = this.notas[matriculaId];
        const mediaSemestral1 =
          ((parseFloat(notasObject.media_1, 10) || 0) +
            (parseFloat(notasObject.media_2, 10) || 0)) /
          2.0;
        const mediaSemestral2 =
          ((parseFloat(notasObject.media_3, 10) || 0) +
            (parseFloat(notasObject.media_4, 10) || 0)) /
          2.0;
        const nota1 =
          mediaSemestral1 < NOTA_MINIMA
            ? Math.max(mediaSemestral1, parseFloat(notasObject.rec_1 || 0, 10))
            : mediaSemestral1;
        const nota2 =
          mediaSemestral2 < NOTA_MINIMA
            ? Math.max(mediaSemestral2, parseFloat(notasObject.rec_2 || 0, 10))
            : mediaSemestral2;

        const notaFinal = this.calcularMedia(nota1, nota2);
        this.$set(notasObject, "nota_final", notaFinal);
      }, 150);
    },
    onCloseWindow(event) {
      if (this.temAlteracoesNaoSalvas || this.promiseCount > 0) {
        event.preventDefault();
        return "Tem certeza que deseja fechar esta página? existem notas não salvas";
      }
      return undefined;
    },
    estaVazio(n) {
      return n === null || n === undefined;
    },
  },
};
</script>
